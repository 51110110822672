import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Alert, Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { get, post, del } from "superagent";

import { Assignment, Test, Extension } from "../../types";
import { formatDate } from '../../helpers';
import AssignmentForm from "./assignment-form";
import Submenu from "./submenu";
import BackLink from "./back-link";
import TestForm from "./test-form";

interface ExtensionProps {
  assignmentId: string
};

const Extensions = ({assignmentId}: ExtensionProps) => {
  const defaultExtensionVals = {
    username: "",
    fullName: "",
    dueDate: (new Date()).toString(),
    maxAttempts: null,
    assignmentId: assignmentId
  };

  const [showNewExtensionPopup, setShowNewExtensionPopup] = useState<boolean>(false);
  const [members, setMembers] = useState<Array<{name: string, username: string}>>([]);
  const [extension, setExtension] = useState<Extension>(defaultExtensionVals);
  const [extensions, setExtensions] = useState<Array<Extension>>([]);
  const [error, setError] = useState<string>("");

  const getMembers = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/extension/members`)
    .withCredentials()
    .then(res => {
      setMembers(res.body);
    }).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const getExtensions = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/extension/${assignmentId}`)
    .withCredentials()
    .then(res => {
      setExtensions(res.body);
    }).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const addExtension = async () => {
    await post(`${process.env.REACT_APP_API_URL}/api/extension/`)
    .withCredentials()
    .send(extension)
    .then(res => {
      getExtensions();
      setShowNewExtensionPopup(false);
      if (error) setError("");
    })
    .catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const deleteExtension = async (extensionId: string) => {
    await del(`${process.env.REACT_APP_API_URL}/api/extension/${extensionId}`)
    .withCredentials()
    .then(() => getExtensions())
    .catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  useEffect(() => {
    getExtensions();
    getMembers();
  }, []);

  return (
    <div style={{marginTop: "40px"}}>
      <h3>Extensions</h3>
      <p>Allow participants to submit past the due date.</p>

      {extensions.length > 0 ?
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Extended Until</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {extensions.map(e => {
              return (
                <tr key={e.id}>
                  <td>{e.fullName}</td>
                  <td>{formatDate(e.dueDate)}</td>
                  <td>
                    <button 
                      className="btn btn-sm btn-link" 
                      onClick={() => e.id ? deleteExtension(e.id) : null}>
                      Delete
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        : null
      }

      <button className="btn btn-primary" onClick={() => setShowNewExtensionPopup(true)}>
        Add Extension +
      </button>

      <Modal 
        show={showNewExtensionPopup} 
        onHide={() => setShowNewExtensionPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Extension</Modal.Title>
        </Modal.Header>


        <Form onSubmit={(e: any) => { e.preventDefault(); addExtension();}}>
          <Modal.Body>
            <p>Extend the due date for a course participant.</p>
            <div className="form-group">
              <select 
                className="form-select"
                required={true}
                defaultValue=""
                onChange={(e) => {
                  const selectedMember = members.find(m => m.username === e.target.value);
                  if (selectedMember) {
                    setExtension({
                      ...extension, 
                      username: e.target.value, 
                      fullName: selectedMember.name
                    })}
                  }
                }>
                <option value="" disabled>Select student</option>
                {members.map(m => {
                  return (
                    <option key={m.username} value={m.username} data-fullname={m.name}>{m.name}</option>
                  );
                })}
              </select>
            </div>
            <br/>
            <div className="form-group">
              <label className="form-label" htmlFor="dueDate">Extend Until:</label>
              <div className="d-flex date-input-container mb-3">
                <span className="input-group-text">
                  <i className="bi bi-calendar"/>
                </span>
                <DatePicker 
                  onChange={(date: any) => setExtension({...extension, dueDate: date})}
                  className="form-control"
                  id="extension-due-date"
                  selected={new Date(extension.dueDate)}
                  name="dueDate"
                  showTimeSelect
                  required={true}
                  dateFormat="MMMM d, yyyy HH:mm"/>
              </div>
            </div>
          </Modal.Body>

          <Alert variant="danger" show={error ? true : false}>
            <Alert.Heading>Error</Alert.Heading>
            <p>{error}</p>
          </Alert> 

          <Modal.Footer>
            <button 
              type="button" 
              className="btn btn-secondary" 
              onClick={() => {
                setShowNewExtensionPopup(false);
                if (error) setError("");
              }}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">Add</button>
          </Modal.Footer>
        </Form>
      </Modal>

    </div>
  )
}

interface AssignmentDisplayProps {
  assignment: Assignment, 
  setIsEditing: (isEditing: boolean) => void
}

const AssignmentDisplay = ({assignment, setIsEditing}: AssignmentDisplayProps) => {
  return (
    <div>

      <div className="option-group">
        <h3>Assignment Setup</h3>
        <br/>
        <h5><b>Name:</b> {assignment.name}</h5>
      </div>
      
      <div className="option-group">
        <b>Description/instructions:</b>
        <br/>
        <div className="text-secondary indented-text">
          {assignment.description ? assignment.description : "None"}
        </div>
      </div>
    
      <div className="option-group">
        <p>
          <b>Submission start date: </b> {formatDate(assignment.startDate)}
        </p>
        <p>
          <b>Submission due date: </b> {formatDate(assignment.dueDate)}
        </p>
      </div>


      <div className="option-group">
        <div className="form-check">
          <input 
            type="checkbox" 
            name="showResults"
            className="form-check-input" 
            disabled checked={assignment.showResults}/>
          <label className="form-check-label" htmlFor="showResults">
          Allow students to see their results
          </label>
        </div>

        <div className="form-check">
          <input 
            type="checkbox" 
            name="showResults"
            className="form-check-input" 
            disabled checked={assignment.maxAttempts !== null}/>
          <label className="form-check-label" htmlFor="showResults">
          Limit number of attempts
          </label>
        </div>

        {assignment.maxAttempts !== null ? 
          <p style={{marginTop: "10px"}}>Maximum attempts: <b>{assignment.maxAttempts}</b></p>
        : null }
      </div>

    </div>
  )
};

const AssignmentView = () => {

  const [assignment, setAssignment] = useState<Assignment>();
  const [tests, setTests] = useState<Array<Test>>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmdeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { assignmentId } = useParams();

  const getAssignment = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/assignment/${assignmentId}`)
    .withCredentials()
    .then(res => {
      const assignmentRes = res.body;
      assignmentRes.libraries = assignmentRes.libraries.split(",");
      setAssignment(assignmentRes);
    }).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const saveAssignment = async (updatedAssignment: Assignment) => {
    setIsLoading(true);
    await post(`${process.env.REACT_APP_API_URL}/api/assignment/${assignmentId}`)
    .withCredentials()
    .send({...updatedAssignment, libraries: updatedAssignment.libraries.toString()})
    .then(res => {
      getAssignment();
      setIsEditing(false);
    }).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
    setIsLoading(false);
  };

  const deleteAssignment = async () => {
    await del(`${process.env.REACT_APP_API_URL}/api/assignment/${assignmentId}`)
    .withCredentials()
    .then(res => {
      window.location.href = `/`;
    }).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const getTests = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/test/${assignmentId}`)
    .withCredentials()
    .then(res => setTests(res.body))
    .catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const createTest = async (test: Test) => {
    await post(`${process.env.REACT_APP_API_URL}/api/test/${assignmentId}`)
    .withCredentials().send(test)
    .then(res => {
      getTests();
    }).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const deleteTest = async (testId: number) => {
    await del(`${process.env.REACT_APP_API_URL}/api/test/${testId}`)
    .withCredentials().then(res => getTests()).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  useEffect(() => {
    getAssignment();
    getTests();
  }, [])

  if (assignment && assignmentId) {
    return (
      <div>
        <BackLink />
        <br/><br/>
        <Submenu />
        <br/>

        <div className="row">

          <div className="col assignment-settings">
            {isEditing ? 
              <div>
                <AssignmentForm 
                  handleSaveAssignment={saveAssignment} 
                  error={error} 
                  assignment={{...assignment}}
                  handleSetError={setError}
                  handleCancel={() => setIsEditing(false)}
                  saveButtonText="Save Changes"
                  loadButtonText="Saving"
                  showTestForm={false}
                  isLoading={isLoading}
                  />
                <br/>
              </div>
              : 
              <div>
                <AssignmentDisplay assignment={assignment} setIsEditing={setIsEditing}/>
                <button className="btn btn-primary" onClick={() => setIsEditing(true)}>Edit</button>
              </div>
            }
          </div>

          <div className="col">
            <h3>Tests</h3>
            <TestForm tests={tests} handleSaveTest={createTest} handleDeleteTest={deleteTest}/>
          </div>

        </div>

        <br/>
        <hr/>
        {assignment.dueDate ? <Extensions assignmentId={assignmentId}/> : null}

        <br/><br/><br/><br/>
        <button className="btn btn-danger btn-sm" onClick={() => setShowConfirmDeleteModal(true)}>
          Delete Assignment
        </button>

        <Modal show={showConfirmdeleteModal} onHide={() => setShowConfirmDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this assignment? All student submissions will also be deleted. 
              <span className="text-danger"><b>This action cannot be undone.</b></span>
            </p>
          </Modal.Body>

          <Modal.Footer>
            <button 
              className="btn btn-secondary" 
              onClick={() => setShowConfirmDeleteModal(false)}>
              Cancel
            </button>
            <button className="btn btn-danger" onClick={() => deleteAssignment()}>Delete</button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  else if (error) {
    return (
      <div className="container">
        <BackLink/>
        <br/><br/>
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{error}</p>
        </Alert>       
      </div>
    );
  };

  return null;

};

export default AssignmentView;
