import { useEffect, useState } from 'react';
import { get } from "superagent";
import { Alert } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import StudentMain from './main';
import AssignmentView from "./assignment-view";

function StudentView() {

  const [successConnectRepo, setSuccessConnectRepo] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const testConnectRepo = async () => {
    get(`${process.env.REACT_APP_API_URL}/api/course/connect`)
    .withCredentials()
    .then(res => {
      setSuccessConnectRepo(true);
      setIsReady(true);
    }).catch(err => {
      setSuccessConnectRepo(false);
      setIsReady(true);
    })
  }

  useEffect(() => {
    testConnectRepo();
  }, []);

  if (isReady) {
    if (successConnectRepo) {
      return (
        <div className="container">
          <Routes>
            <Route path='/' element={<StudentMain/>} />
            <Route path='/assignment/:assignmentId' element={<AssignmentView/>} />
          </Routes>
        </div>
      );
    }
    else {
      return (
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>Unable to connect to Gitlab course repository.</p>
        </Alert> 
      );
    }
  }

  return null;
}

export default StudentView;
