interface LoadingButtonProps {
  isLoading: boolean;
  buttonText: string;
  loadingText: string;
}

const LoadingButton = ({isLoading, buttonText, loadingText}: LoadingButtonProps) => {

  if (isLoading) {
    return (
      <button className="btn btn-primary" type="button" disabled>
        <div className="d-flex align-items-center">
          <span 
            className="spinner-border spinner-border-sm" 
            role="status" 
            aria-hidden="true" 
            style={{marginRight: "5px"}}/>
          <span className="sr-only">{loadingText}</span>
        </div>
      </button>
    );
  }
  else {
    return <button className="btn btn-primary" type="submit">{buttonText}</button>;
  }
};

export default LoadingButton;
