import { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import { Alert } from "react-bootstrap";

import { Assignment, Test } from "../../types";
import LoadingButton from "../shared/loading-button";
import TestForm from "./test-form";

interface AssignmentFormProps {
  handleSaveAssignment: (assignment: Assignment, test?: Test[] | undefined, selectedLibraries?: string[] | undefined) => void;
  error: string;
  assignment: Assignment;
  handleSetError: (params: any) => void;
  handleCancel: () => void;
  saveButtonText: string;
  isLoading: boolean;
  loadButtonText: string;
  showTestForm: boolean;
};

const popularPythonLibraries = [
  "pandas", "scipy", "matplotlib", "io", "sys", "pickle", "pytest", "Keras", "tensorflow", "pytorch", "scikit-learn", "math"
];

function AssignmentForm({
  handleSaveAssignment, 
  error, 
  assignment,
  handleSetError, 
  handleCancel,
  saveButtonText,
  isLoading,
  loadButtonText,
  showTestForm
}: AssignmentFormProps) {

  const [newAssignment, setNewAssignment] = useState<Assignment>(assignment);
  const [tests, setTests] = useState<Array<Test>>([]);
  const [showAttemptsInput, setShowAttemptsInput] = useState<boolean>(assignment.maxAttempts !== null ? true : false);
  
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, name, value, checked } = e.target;
    if (type === "checkbox") {
      if (e.target.classList.contains("pyLibCheckbox")) {
        const newAssignmentCopy = {...newAssignment}
        if (e.target.checked) {
          newAssignmentCopy.libraries.push(e.target.name)
          setNewAssignment(newAssignmentCopy);
        }
        else {  
          const libraryIndex = newAssignmentCopy.libraries.indexOf(e.target.name)
          newAssignmentCopy.libraries.splice(libraryIndex, 1);
          setNewAssignment(newAssignmentCopy);
        }
      } else {
        setNewAssignment(prev => ({...prev, [name]: checked}));
      }
    } else {
      setNewAssignment(prev => ({...prev, [name]: value}));
    }
  };
  console.log("NEW ASSIGNMENT", newAssignment);
  
  const saveTest = (test: Test) => {
    const newTests = [...tests, test];
    setTests(newTests);
  }

  const removeTest = (testIndex: number) => {
    const testsCopy = [...tests];
    testsCopy.splice(testIndex, 1);
    setTests(testsCopy);
  }

  const handleDateChange = (date: Date, name: string) => {
    setNewAssignment({...newAssignment, [name]: date});
  }

  return (
    <div>
      <form onSubmit={(e) => {e.preventDefault(); handleSaveAssignment(newAssignment, tests)}}> 

        <label className="form-label" htmlFor="name">Assignment name</label>
        <div className="d-flex date-input-container mb-3">
          <input 
            className="form-control"
            onChange={(e: any) => handleFormChange(e)}
            value={newAssignment.name}
            name="name"
            required/>
        </div>

        <label className="form-label" htmlFor="description">Description/instructions</label>
        <div className="d-flex date-input-container mb-3">
          <textarea 
            className="form-control"
            onChange={(e: any) => handleFormChange(e)}
            value={newAssignment.description}
            name="description"/>
        </div>
        <label className="form-label" htmlFor="startDate">Start date</label>
        <div className="d-flex date-input-container mb-3">
          <span className="input-group-text">
            <i className="bi bi-calendar"/>
          </span>
          <DatePicker 
            onChange={(date: Date) => handleDateChange(date, "startDate")}
            className="form-control"
            name="startDate"
            selected={new Date(newAssignment.startDate)}
            aria-describedby="basic-addon1"
            showTimeSelect
            dateFormat="MMMM d, yyyy HH:mm"/>
        </div>

        <label className="form-label" htmlFor="dueDate">Due date</label>
        <div className="d-flex date-input-container mb-3">
          <span className="input-group-text">
            <i className="bi bi-calendar"/>
          </span>
          <DatePicker 
            onChange={(date: Date) => handleDateChange(date, "dueDate")}
            className="form-control"
            selected={new Date(newAssignment.dueDate)}
            name="dueDate"
            showTimeSelect
            dateFormat="MMMM d, yyyy HH:mm"/>
        </div>

        <div className="d-flex date-input-container mb-3">
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="checkbox" 
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
              checked={newAssignment.showResults}
              name="showResults"/>
            <label className="form-check-label" htmlFor="showResults">
              Allow students to see their results
            </label>
          </div>
        </div>

        <div className="d-flex date-input-container mb-3">
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="checkbox" 
              onChange={(e) => {
                setShowAttemptsInput(e.target.checked);
                if (!e.target.checked) {
                  setNewAssignment({...assignment, maxAttempts: null})
                }
              }}
              checked={showAttemptsInput}
              name="showResults"/>
            <label className="form-check-label" htmlFor="showResults">
              Limit number of attempts
            </label>
          </div>
        </div>
        {showAttemptsInput ? 
          <div>
            <label className="form-label" htmlFor="maxAttempts">Maximum attempts</label>
            <div className="d-flex">
              <div className="d-flex date-input-container mb-3">
                <input 
                  className="form-control"
                  type="number"
                  min="0"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                  value={newAssignment.maxAttempts ? newAssignment.maxAttempts : 1}
                  name="maxAttempts"/>
              </div>
            </div>
          </div>
          : null
        }

        {showTestForm ? 
          <div>
            <br/>
            <h4>Tests</h4>
            <TestForm handleSaveTest={saveTest} handleDeleteTest={removeTest} tests={tests}/>
            <br/>
          </div>
          : null 
        }

        {error ?
          <Alert variant="danger" onClose={() => handleSetError("")} dismissible>
            <Alert.Heading>Error</Alert.Heading>
            <p>{error}</p>
          </Alert> 
        : null }
        {/****UPDATED******/}
        <div className="d-flex mx-3">
          <label className="form-check-label me-m">Add Python libraries to tests:</label>
          <div className="row">
            {popularPythonLibraries.map((library) => (
              <div key={library} className="col-3">
                <div className="form-check">
                  <input
                    className="form-check-input pyLibCheckbox"
                    type="checkbox"
                    name={library}
                    id={`pyLibCheckbox-${library}`}
                    value={library}
                    onChange={handleFormChange}
                    checked={assignment.libraries.includes(library)}
                  />
                  <label className="form-check-label" htmlFor={`pyLibCheckbox-${library}`}>
                    {library}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
        <br/>

        <LoadingButton isLoading={isLoading} buttonText={saveButtonText} loadingText={loadButtonText}/>
        <button 
          className="btn btn-secondary" 
          style={{marginLeft: "15px"}} 
          onClick={() => handleCancel()}>
          Cancel
        </button>

      </form>
    </div>
  );
}

export default AssignmentForm;
