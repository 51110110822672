import { useState } from 'react';

import { Test } from "../../types";

interface TestFormProps {
  handleSaveTest: (test: Test) => void;
  handleDeleteTest: (testId: number) => void;
  tests: Array<Test>
};

const defaultNewTestVals = {
  description: "",
  fileName: "", 
  functionName: "", 
  input: "", 
  expectedOutput: ""
}

function TestForm({
  handleSaveTest,
  handleDeleteTest,
  tests
}: TestFormProps) {

  const [isAddingTest, setIsAddingTest] = useState<boolean>(false);
  const [testValues, setTestValues] = useState<Test>(defaultNewTestVals);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTestValues({...testValues, [e.target.name]: e.target.value})
  }

  const saveTest = (e: React.FormEvent) => {
    e.preventDefault();
    handleSaveTest(testValues);
    setIsAddingTest(false);
    resetTestForm();
  }

  const resetTestForm = () => {
    setTestValues(defaultNewTestVals);
    setIsAddingTest(false);
  }
  
  return (
    <div>
      {tests.length > 0 ?
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Description</th>
              <th>File Name</th>
              <th>Function Name</th>
              <th>Function Input (hidden)</th>
              <th>Expected Output (hidden)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tests.map((test, i) => {
              return (
                <tr key={i}>
                  <td>{test.description}</td>
                  <td>{test.fileName}</td>
                  <td>{test.functionName}</td>
                  <td>{test.input}</td>
                  <td>{test.expectedOutput}</td>
                  <td>
                    <button 
                      className="btn btn-sm btn-link" 
                      type="button"
                      onClick={() => handleDeleteTest(test.id ? test.id : i)}>
                      Delete
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        : null
      }

      <br/>

      {isAddingTest ? 
        <div className="d-flex justify-content-between align-items-end">
          <div>
            <label className="form-label" htmlFor="description">Description:</label>
            <input
              className="form-control" 
              name="description"
              value={testValues.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
            />
          </div>
          &nbsp;
          <div>
            <label className="form-label" htmlFor="fileName">File name:</label>
            <input
              className="form-control" 
              name="fileName"
              value={testValues.fileName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
            />
          </div>
          &nbsp;
          <div>
            <label className="form-label" htmlFor="functionName">Function name:</label>
            <input
              className="form-control" 
              name="functionName"
              value={testValues.functionName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
            />
          </div>
          &nbsp;
          <div>
            <label className="form-label" htmlFor="input">Input (hidden from participants):</label>
            <input 
              className="form-control" 
              name="input"
              value={testValues.input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
            />
          </div>
          &nbsp;
          <div>
            <label className="form-label" htmlFor="expectedOutput">Expected output (hidden from participants):</label>
            <input
              className="form-control" 
              name="expectedOutput"
              value={testValues.expectedOutput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
            />
          </div>
          &nbsp;
          <button type="submit" className="btn btn-success btn-sm" onClick={(e) => saveTest(e)}>Save</button>
          &nbsp;
          <button className="btn btn-secondary btn-sm" onClick={() => resetTestForm()}>Cancel</button>
        </div>
        :
        <button className="btn btn-primary btn-sm" onClick={() => setIsAddingTest(true)}>Add Test +</button>
      }

    </div>
  );
}

export default TestForm;
