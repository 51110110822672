import { useEffect, useState } from 'react';
import { get, post } from "superagent";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { Assignment } from "../../types";
import { formatDate, getAssignmentResult, handleError } from "../../helpers";

function StudentMain() {

  const [assignments, setAssignments] = useState<Array<Assignment>>([]);
  const [error, setError] = useState<string>("");

  const getAssignments = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/assignment`)
    .withCredentials()
    .then(res => {
      setAssignments(res.body);
    }).catch(err => handleError(err, setError));
  };

  useEffect(() => {
    getAssignments();
  }, []);

  return (
    <div>  
      <h2>Your Assignments</h2>
      <p>Select an assignment below to make a submission or view your results.</p>

      <br/>

      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Date</th>
            <th>Due Date</th>
            <th>Submitted</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {assignments.length > 0 ? assignments.map(a => {
            const now = new Date();
            const startDate = new Date(a.startDate);
            const submissionVals = Object.values(a.submissions);
            const submissions: any = submissionVals.length > 0 ? submissionVals[0] : [];
            const result = getAssignmentResult(submissions);
            let dueDate = a.dueDate;
        
            // Use extension dueDate if it exists
            if (a.extensions && a.extensions.length > 0) {
              dueDate = a.extensions[0].dueDate;
            }
            
            return (
              <tr key={a.id}>
                <td>
                  {now >= startDate ?
                    <Link to={`/assignment/${a.id}`}>{a.name}</Link>
                  : a.name}
                </td>
                <td>{formatDate(a.startDate)}</td>
                <td>{now >= startDate ? formatDate(dueDate) : "-"}</td>
                <td className="text-success">{submissions.length > 0 ? <i className="bi bi-check-lg"/> : ""}</td>
                <td className={`result-text ${result}`}>{result}</td>
              </tr>
            )
          }) : <tr><td colSpan={5} style={{textAlign: "center"}}>No assignments yet.</td></tr>}
        </tbody>
      </table>

      {error ?
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          <Alert.Heading>Error</Alert.Heading>
          <p>{error}</p>
        </Alert> 
      : null }

    </div>
  );
}

export default StudentMain;
