import { useEffect, useState } from 'react';
import { get, post } from "superagent";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import AssignmentForm from "./assignment-form";
import { Assignment, Test } from "../../types";
import { formatDate } from "../../helpers";

const blankAssignment = {
  name: "",
  description: "",
  startDate: (new Date()).toString(),
  dueDate: (new Date()).toString(),
  fileName: "",
  maxAttempts: null,
  showResults: true,
  submissions: [],
  tests: [],
  libraries: []
}

function InstructorMain() {

  const [assignments, setAssignments] = useState<Array<Assignment>>([]);
  const [showNewAssignmentPopup, setShowNewAssignmentPopup] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAssignments = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/assignment/`)
    .withCredentials()
    .then(res => {
      setAssignments(res.body);
    }).catch(err => {
      console.log(err)
    });
  };

  const addAssignment = async (assignment: Assignment, tests?: Array<Test>) => {
    const libraryString = assignment.libraries.toString().replace(/\s+/g, '');
    setIsLoading(true);
    await post(`${process.env.REACT_APP_API_URL}/api/assignment/`)
      .withCredentials()
      .send({assignment: {...assignment, libraries: libraryString}, tests})
      .then(res => {
        getAssignments();
        setShowNewAssignmentPopup(false);
      })
      .catch(err => {
        console.log(err)
        if (err.response.text) {
          setError(err.response.text);
        }
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getAssignments();
  }, []);

  return (
    <div>  
      <h2>Course Assignments</h2>
      <p>Select an assignment below to edit or view submissions.</p>

      <div className="d-flex justify-content-between align-items-end">

        <div className="font-monospace d-flex align-items-center">
          <div style={{width: "10px", height: "10px", backgroundColor: "springgreen"}}/>
            &nbsp;Connected to gitlab repo
          </div>
          <button 
            className="btn btn-primary" 
            onClick={() => setShowNewAssignmentPopup(true)}>
              New Assignment +
          </button>
      </div>

      <br/>

      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Date</th>
            <th>Due Date</th>
            <th>Submissions</th>
          </tr>
        </thead>
        <tbody>
          {assignments.length > 0 ? assignments.map(a => {
            return (
              <tr key={a.id}>
                <td><Link to={`/assignment/${a.id}`}>{a.name}</Link></td>
                <td>{formatDate(a.startDate)}</td>
                <td>{formatDate(a.dueDate)}</td>
                <td>{Object.keys(a.submissions).length}</td>
              </tr>
            )
          }) : <tr><td colSpan={4} style={{textAlign: "center"}}>No assignments yet.</td></tr>}
        </tbody>
      </table>

      <Modal 
        dialogClassName="modal-max-1000" 
        show={showNewAssignmentPopup} 
        onHide={() => setShowNewAssignmentPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Assignment</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AssignmentForm 
            handleSaveAssignment={addAssignment} 
            error={error} 
            assignment={blankAssignment}
            handleSetError={setError}
            handleCancel={() => setShowNewAssignmentPopup(false)}
            saveButtonText="Create"
            loadButtonText="Creating"
            showTestForm={true}
            isLoading={isLoading}
            />
        </Modal.Body>

      </Modal>

    </div>
  );
}

export default InstructorMain;
