import { useEffect, useState } from 'react';
import { get, post } from "superagent";
import { Routes, Route } from "react-router-dom";
import { Alert } from "react-bootstrap";

import InstructorMain from "./main";
import AssignmentView from "./assignment-view";
import Submissions from "./submissions";
import LoadingButton from '../shared/loading-button';

function InstructorView() {

  const [successConnectRepo, setSuccessConnectRepo] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [repoId, setRepoId] = useState<string>("");
  const [repoToken, setRepoToken] = useState<string>("");
  const [error, setError] = useState<string>("");

  const testConnectRepo = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/course/connect`)
    .withCredentials()
    .then(res => {
      setSuccessConnectRepo(true);
      setIsReady(true);
    }).catch(err => {
      setSuccessConnectRepo(false);
      setIsReady(true);
    });
  }

  const addRepo = async () => {
    setIsLoading(true);
    if (process.env.REACT_APP_API_URL) {
      await post(`${process.env.REACT_APP_API_URL}/api/course/`)
      .withCredentials() 
      .send({repoId, repoToken})
      .then(() => testConnectRepo())      
      .catch(err => {
        console.log(err);
        if (err.response.text) {
          setError(err.response.text);
        }
      })
    } else {
      
      setSuccessConnectRepo(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    testConnectRepo();
  }, [])


  if (isReady) {
    if (successConnectRepo) {
      return (
        <div className="container">
          <Routes>
            <Route path='/' element={<InstructorMain/>} />
            <Route path='/assignment/:assignmentId' element={<AssignmentView/>} />
            <Route path='/assignment/:assignmentId/submissions' element={<Submissions/>} />
          </Routes>
        </div>
      );
    }

    return (
      <div className="container">
        <h2>Python Auto-Graded Assignments</h2>
        <p>
          In order to use Python Auto-Graded Assignments, this course must be connected to a Gitlab repository. 
          Please enter repository credentials below.
        </p>
        <form className="d-flex" onSubmit={(e) => { e.preventDefault(); addRepo(); }}>
          <input 
            className="form-control" 
            style={{maxWidth: "200px", marginRight: "10px"}}
            onChange={(e) => setRepoId(e.target.value)} 
            placeholder="Repository ID"
            required/>
          <input 
            className="form-control" 
            style={{marginRight: "10px"}}
            onChange={(e) => setRepoToken(e.target.value)} 
            placeholder="Repository token"
            required/>
  
          <LoadingButton isLoading={isLoading} buttonText="Submit" loadingText="Connecting"/>
  
        </form>
  
        <br/>
  
        {error ?
          <Alert variant="danger" onClose={() => setError("")} dismissible>
            <Alert.Heading>Error</Alert.Heading>
            <p>{error}</p>
          </Alert> 
        : null }
  
      </div>
    );

  }

  return null;

  
}

export default InstructorView;
