import { Test } from "../types";

export const formatDate = (dateString: string) => {
  if (dateString === null) {
    return "None";
  }
  const date = new Date(dateString);
  const month = date.toLocaleString('default', {month: 'long' });
  const hours = (date.getHours().toString()).padStart(2, '0');
  const minutes = (date.getMinutes()).toString().padStart(2, '0');
  return `${date.getDate()} ${month} ${date.getFullYear()} ${hours}:${minutes}`;
};

// Takes an array of submissions and returns the current result of the assignment
// e.g. an assignment with 5 failed submissions will return "failed", an assignment
// with 1 success and 4 failed will return "success"
export const getAssignmentResult = (submissions: Array<any>) => {
  if (submissions.length > 0) {
    const hasSuccess = submissions.find(sub => sub.result === "success");
    return hasSuccess ? "success" : submissions[0].result;
  }
  return "";
};

// Takes a results object string and array of tests and returns 
// the number of passed tests, and the results as an object
export const convertResultsToObj = (resultsString: string, tests: Array<Test>) => {
  const pattern = /BEGIN_RESULTS: ({.*?}) :END_RESULTS/;
  let numTestsPassed = 0;
  let results: { [key: string]: boolean } = {};

  // Check if resultsString matches the expected pattern
  const matches = resultsString && resultsString.match(pattern);

  if (matches && matches.length > 1) {
    // Extract and process the matched results string
    let resultsJSONString = matches[1].toLowerCase().replaceAll("'", '"').trim();

    // Ensure keys and boolean values are properly quoted
    resultsJSONString = resultsJSONString.replace(/(\w+):/g, '"$1":');
    resultsJSONString = resultsJSONString.replace(/: (true|false)/g, ': "$1"');

    try {
      // Attempt to parse the JSON string
      const parsedResults = JSON.parse(resultsJSONString) as { [key: string]: string};

      // Convert string "true" and "false" back to boolean values
      for (let key in parsedResults) {
        results[key] = parsedResults[key] === "true";
      }

      // Count the number of tests that passed
      numTestsPassed = Object.values(results).filter(r => r === true).length;
    } catch (error) {
      console.error("Error parsing results JSON:", error);
      // Handle parsing error (e.g., log, set default values)
      results = {};
      numTestsPassed = 0;
    }
  } else {
    console.warn("No valid results pattern found in:", resultsString);
  }
  return { numTestsPassed, results };
};


// Takes a HTTP res error and sends the text (if any) to the callback
export const handleError = (err: any, callback: (message: string) => void) => {
  console.log(err);
  if (err.response.text) {
    callback(err.response.text);
  }
}