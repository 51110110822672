import { Link } from "react-router-dom";

const BackLink = () => {
  return (
    <Link to="/">
      <button className="btn btn-primary btn-sm">
        <i className="bi bi-arrow-left"/> Back to assignments
      </button>
    </Link>
  );
}

export default BackLink;