import { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Alert, Accordion } from "react-bootstrap";
import { get, post } from "superagent";
import parse from 'html-react-parser';

import { Assignment, Submission } from "../../types";
import { formatDate, convertResultsToObj } from "../../helpers";
import LoadingButton from "../shared/loading-button";
import SubmissionTestTable from '../shared/submission-test-table';

var AU = require('ansi_up');
var ansi_up = new AU.default;

const BackLink = () => {
  return (
    <Link to="/">
      <button className="btn btn-primary btn-sm">
        <i className="bi bi-arrow-left"/> Back to assignments
      </button>
    </Link>
  );
}

interface SubmissionFormProps {
  assignment: Assignment,
  submissions: Array<Submission>,
  handleSetError: (err: string) => void,
  handleGetSubmissions: () => void,
  dueDatePassed: boolean|undefined,
  startDatePassed: boolean|undefined
}

const SubmissionForm = ({
  assignment, 
  submissions, 
  handleSetError, 
  handleGetSubmissions,
  dueDatePassed,
  startDatePassed
}: SubmissionFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<string>("");
  const hasPassedSubmission = submissions.length > 0 && submissions.some(s => s.result === "success");

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const uploadAssignment = async (e: React.FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    if (file && assignment) {

      const hasPendingSubmission = submissions.find(s => s.result === "pending");

      if (hasPendingSubmission) {
        handleSetError("Please wait for your pending submission to process before making another submission.");
        setIsLoading(false);
        return;
      }

      const formData: any = new FormData();
      await formData.append('file', file);
      await post(`${process.env.REACT_APP_API_URL}/api/submission/${assignment.id}`)
      .withCredentials() 
      .send(formData)
      .then(() => handleGetSubmissions())      
      .catch(err => {
        console.log(err);
        if (err.response.text) {
          handleSetError(err.response.text);
        }
      })
    }
    setIsLoading(false);
  };

  if (hasPassedSubmission) {
    return (
      <div className="text-success" style={{fontWeight: "bold"}}>
        <i className="bi bi-check-lg"/>&nbsp;
        You have completed and passed this assignment
      </div>
    ) 
  }

  if (!startDatePassed) {
    return (
      <div className="text-danger" style={{fontWeight: "bold"}}>
        Submissions are not open for this assignment yet. Please check back after the start date has passed.
      </div>
    )
  }

  if (!hasPassedSubmission && dueDatePassed) {
    return (
      <div className="text-danger" style={{fontWeight: "bold"}}>
        The due date has passed for this assignment.
      </div>
    ) 
  }

  else return (
    <div>
      <br/>
      <h4>Make a Submission</h4>
      <br/>

      <div style={{maxWidth: "500px"}}>
        <form 
          encType="multipart/form-data" 
          onSubmit={(e) => uploadAssignment(e)}>
          <label htmlFor="zip-upload" className="form-label">Upload zip file:</label>

          <div className="d-flex">
            <input 
              className="form-control" 
              type="file" 
              id="zip-upload" 
              accept=".zip" 
              onChange={(e) => handleFileChange(e)}
              style={{marginRight: "10px"}}
              required/>

            <LoadingButton isLoading={isLoading} buttonText="Submit" loadingText="Submitting"/>

          </div>
        </form>
      </div>
    </div>
  )
}
const AssignmentView = () => {

  const [assignment, setAssignment] = useState<Assignment>();
  const [submissions, setSubmissions] = useState<Array<Submission>>([]);
  const [error, setError] = useState<string>("");
  const { assignmentId } = useParams();

  const getAssignment = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/assignment/${assignmentId}`)
    .withCredentials()
    .then(res => setAssignment(res.body)).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  const getSubmissions = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/submission/${assignmentId}`)
    .withCredentials()
    .then(res => {
      setSubmissions(res.body);
    }).catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      }
    });
  };

  useEffect(() => {
    getAssignment();
    getSubmissions();
  }, []);
   
  if (assignment) {

    const now = new Date();
    const startDate = assignment.startDate ? new Date(assignment.startDate) : undefined;
    let dueDateString = assignment.dueDate ? assignment.dueDate : "";
    let dueDate = dueDateString ? new Date(dueDateString) : undefined;

    // Use extension dueDate if it exists
    if (assignment.extensions && assignment.extensions.length > 0) {
      dueDate = new Date(assignment.extensions[0].dueDate);
      dueDateString = assignment.extensions[0].dueDate;
    }
      
    let startDatePassed = startDate && now > startDate;
    let dueDatePassed = dueDate && now > dueDate;

    return (
      <div>
        <BackLink />

        <br/><br/>
        <h3>{assignment.name}</h3>

        <div className="option-group">
          {assignment.startDate && !startDatePassed ? 
            <div><b>Submission start date: </b> {formatDate(assignment.startDate)}</div>
          : null}
          {assignment.dueDate ? 
            <div><b>Submission due date: </b> {formatDate(dueDateString)}</div>
          : null}
        </div>

        <div className="option-group">
          <p>{assignment.description}</p>
        </div>

        <div className="option-group">
          <p>
            <b>Total attempts:</b> {submissions.length}
            <br/>
            <b>Attempts left:</b> {dueDatePassed ? 0 : (assignment.maxAttempts ? assignment.maxAttempts - submissions.length : "Unlimited")}
          </p>
          <p><b>Tool tip for MacOS users:</b> using the default Archive Utility may cause issues on upload and parsing your solution. 
          <br/>If your tests are failing with the correct solution, try zipping your assignment through the Terminal.
          <br/>
          <br/>Terminal Example: macUser@My-MacBook Documents % zip assignments.zip test1.py test2.py
          <br/>Format: % zip name-of-new-folder.zip name-of-file name-of-file  
          </p>
        </div>

        <SubmissionForm 
          assignment={assignment} 
          submissions={submissions} 
          handleSetError={setError}
          handleGetSubmissions={getSubmissions}
          dueDatePassed={dueDatePassed}
          startDatePassed={startDatePassed}/>

        {error ?
          <Alert variant="danger" onClose={() => setError("")} dismissible style={{marginTop: "20px"}}>
            <Alert.Heading>Error</Alert.Heading>
            <p>{error}</p>
          </Alert> 
        : null }

        <br/><br/>
        
        {submissions.length > 0 ? <h4>Submission History</h4> : null}

        <br/>

        {submissions.length > 0 ? 
          <Accordion defaultActiveKey="">
            {submissions.reverse().map((sub: Submission, i: number) => {
              const numTests = assignment.tests.length;
              const { numTestsPassed, results } = convertResultsToObj(sub.consoleOutput, assignment.tests);
              let status = `${sub.result} ${numTestsPassed}/${numTests}`;

              if (!sub.result) {
                status = "Submitted"
              }

              if (sub.result === "pending") {
                status = sub.result;
              }

              return (
                <Accordion.Item key={i} eventKey={i.toString()}>
                  <Accordion.Header>
                    <div className="d-flex student-submission-header">
                      <span>{formatDate(sub.date)}</span>
                      <span className={`result-text ${sub.result ? sub.result : "submitted"}`}>
                        {status}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {sub.result ? 
                      (sub.result === "pending" ? 
                        (assignment.showResults ? 
                          <p>This submission is still pending. Please refresh the page in a few minutes to view results.</p>
                          : <p>This submission is still pending. Once processed, the results will be sent to your instructor.</p>
                        )
                        : 
                        <div>
                          <SubmissionTestTable results={results} tests={assignment.tests}/>
                          <h5>Console Output</h5>
                          <div className="code-container">
                            <code>
                              <pre id={`${sub.id}-console-output`}>
                                {parse(ansi_up.ansi_to_html(sub.consoleOutput))}
                              </pre>
                            </code>
                          </div> 
                        </div>
                      )
                      : <div>
                          <p>This assignment has been successfully submitted.</p>
                          <a href={`${process.env.REACT_APP_API_URL}/api/submission/code?submissionId=${sub.id}&assignmentId=${assignmentId}`}>Download code</a>
                        </div>
                    }
                  </Accordion.Body>
                </Accordion.Item >
              )
            })}
          </Accordion>
        : null
      }

      </div>
    );
  }

  else if (error) {
    return (
      <div className="container">
        <BackLink/>
        <br/><br/>
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{error}</p>
        </Alert>       
      </div>
    );
  };

  return null;

};

export default AssignmentView;
