import { useEffect, useState } from 'react';
import { get } from "superagent";
import { useParams } from "react-router-dom";
import { Accordion, Modal } from "react-bootstrap";
import parse from 'html-react-parser';

import Submenu from "./submenu";
import BackLink from "./back-link";
import SubmissionTestTable from '../shared/submission-test-table';
import { Submission, Test, Results } from "../../types";
import { formatDate, convertResultsToObj, handleError } from "../../helpers";

var AU = require('ansi_up');
var ansi_up = new AU.default;

const noResultsMessage = 'This submission has no results, meaning an error occured before tests were run. Click the "View" button under the Output column for more information.';

function Submissions() {

  const [submissions, setSubmissions] = useState<{[userId: string]: Array<Submission>}>({});
  const [tests, setTests] = useState<Array<Test>>([]);
  const [error, setError] = useState<string>("");
  const [showOutputModal, setShowOutputModal] = useState<boolean>(false);
  const [currentSubmissionOutput, setCurrentSubmissionOutput] = useState<string>("");
  const [showResultsModal, setShowResultsModal] = useState<boolean>(false);
  const [selectedResults, setSelectedResults] = useState<Results>({});

  const { assignmentId } = useParams();

  const getSubmissions = async () => {
    try {
      const res = await get(`${process.env.REACT_APP_API_URL}/api/submission/all/${assignmentId}`).withCredentials();
      setSubmissions(res.body);
    } catch (err) {
      handleError(err, setError);
    }
  };

  const getTests = async () => {
    try {
      const res = await get(`${process.env.REACT_APP_API_URL}/api/test/${assignmentId}`).withCredentials();
      setTests(res.body);
    } catch (err) {
      handleError(err, setError);
    }
  };

  useEffect(() => {
    getSubmissions();
    getTests();
  }, []);

  return (
    <div>
      <BackLink />
      <br/><br/>
      <Submenu />
      <br/>

      <h4>Submissions</h4>

      {Object.keys(submissions).length > 0 ? 
          <Accordion defaultActiveKey="">
            {Object.keys(submissions).map((userId: string) => {
              const userSubs = submissions[userId];
              const hasSuccess = userSubs.find(sub => sub.result === "success");
              let result = hasSuccess ? "success" : userSubs[0].result;
              return (
                <Accordion.Item eventKey={userId} key={userId}>
                  <Accordion.Header>
                    <div className="d-flex align-items-center student-submission-header">
                      <span style={{fontSize: "20px"}}>{userId}</span>
                      <span className={`result-text ${result}`}>
                        {result}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Total submissions: <b>{userSubs.length}</b></p>
                    <h5>Submission history</h5>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Result</th>
                          <th>Output</th>
                          <th>Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userSubs.map(sub => {
                          const codeUrl = `${process.env.REACT_APP_API_URL}/api/submission/code?submissionId=${sub.id}&assignmentId=${assignmentId}`;
                          const { numTestsPassed, results } = convertResultsToObj(sub.consoleOutput, tests);
            
                          return (
                            <tr key={sub.id}>
                              <td>{formatDate(sub.date)}</td>
                              <td>
                                <span className={`result-text ${sub.result}`}>{sub.result}&nbsp;&nbsp;</span>
                                {sub.result !== "pending" ? 
                                  <span className="link" onClick={() => {
                                    setShowResultsModal(true);
                                    setSelectedResults(results);
                                  }}>
                                    {numTestsPassed}/{tests.length}
                                  </span>
                                : null}
                              </td>
                              <td>
                                <button 
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  setShowOutputModal(true);
                                  setCurrentSubmissionOutput(sub.consoleOutput);
                                }}>View</button>
                              </td>
                              <td><a href={codeUrl}>Download</a></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item >
              )
            })}
          </Accordion>
        : <p>There are no submissions for this assignment yet.</p>
      }

      <Modal 
        dialogClassName="modal-max-800" 
        show={showResultsModal} 
        onHide={() => {
          setShowResultsModal(false);
          setSelectedResults({});
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Test Results</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {Object.keys(selectedResults).length === 0 ? 
            <p>{noResultsMessage}</p> : 
            <SubmissionTestTable results={selectedResults} tests={tests}/>
          }
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => {
            setShowOutputModal(false);
            setCurrentSubmissionOutput("");
          }}>Close</button>
        </Modal.Footer>
      </Modal>


      <Modal 
        dialogClassName="modal-max-800" 
        show={showOutputModal} 
        onHide={() => {
          setShowOutputModal(false);
          setCurrentSubmissionOutput("");
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Console Output</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="code-container">
            <code>
              <pre>
                {parse(ansi_up.ansi_to_html(currentSubmissionOutput))}
              </pre>
            </code>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => {
            setShowOutputModal(false);
            setCurrentSubmissionOutput("");
          }}>Close</button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Submissions;
