import { Test, Results } from "../../types";

interface SubmissionTestTableProps {
  results: Results,
  tests: Array<Test>
};

export const SubmissionTestTable = ({results, tests}: SubmissionTestTableProps) => {

  if (tests.length > 0) {
    const hasInput = tests[0].input;
    const hasOutput = tests[0].expectedOutput;
  
    return (
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Test description</th>
            <th>File</th>
            <th>Function</th>
            {hasInput ? <th>Input</th> : null}
            {hasOutput ? <th>Expected Output</th> : null}
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {tests.map(t => {
            if (t.id) {
              return (
                <tr key={t.id}>
                  <td>{t.description}</td>
                  <td>{t.fileName}</td>
                  <td>{t.functionName}</td>
                  {hasInput ? <td>{t.input}</td> : null}
                  {hasOutput ? <td>Expected Output</td> : null} 
                  <td className={`result-text ${results[t.id] ? "success" : "failed"}`}>
                    {results[t.id] ? "True" : "False"}
                  </td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    )
  }
  else {
    return <p>This assignment has no tests.</p>
  }
};

export default SubmissionTestTable;