import { useEffect, useState } from 'react';
import { get } from "superagent";

import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import InstructorView from './components/instructor/';
import StudentView from "./components/student";

function App() {

  const [user, setUser] = useState<any>(null);
  const [isReady, setIsReady] = useState<boolean>(false);

  const validateUser = async () => {
    // Validate LTI
    await get(`${process.env.REACT_APP_API_URL}/validate-user`)
    .withCredentials()
    .then((res: any) => {
      setUser(res.body)
      return true;
    })
    .catch((err: any) => {
      console.log(err);
      // Handle error
    });
    setIsReady(true);
  }

  useEffect(() => {
    validateUser();
  }, [])

  if (isReady) {

    if (user) {
      if (user.role === "instructor") {
        return (
          <InstructorView />
        );
      }
      if (user.role === "student") {
        return (
          <StudentView />
        );
      }
    }

    return (
      <div className="App">
        <header className="App-header">
          No session
        </header>
      </div>
    );

  }

  return null;

}

export default App;
