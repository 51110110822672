import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

const Submenu = () => {
  const { assignmentId } = useParams();

  return (
    <div className="sub-menu">
      <NavLink 
        to={`/assignment/${assignmentId}`} 
        end
        className={({ isActive }) => isActive ? "active" : ""}>
        <div className="sub-menu-item">Assignment Settings</div>
      </NavLink>
      <NavLink 
        to={`/assignment/${assignmentId}/submissions`}
        className={({ isActive }) => isActive ? "active" : ""}>
        <div className="sub-menu-item">Submissions</div>
      </NavLink>
    </div>
  );
}

export default Submenu;